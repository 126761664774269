const { mapState } = require('vuex')
module.exports = {
    computed: mapState({
        count: state => state.count,

        countAlias: 'count',

        countPlusLocalState (state) {
            return state.count + this.localCount
        }
    }),
    // baseUrl: 'http://localhost:8085/',
    baseUrl: 'https://iot.vestatest.ir',
    // baseUrl: 'https://iot.radvinfountain.ir',
    // serverBaseUrl: 'https://iot.radvinfountain.ir'
    serverBaseUrl: 'https://iot.vestatest.ir'

  }